<template>
<v-card>
  <v-card-title v-if='title' class='overline' v-text='title' />
  <v-card-text>
    <v-row>
      <v-col cols=12 >
        <v-tabs v-model='activeTab' >
          <v-tab v-for='section in sections' :key='section.id' v-text='section.text' />
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tabs-items v-model='activeTab'>
            <!-- {{section}} -->
            <v-tab-item v-for='section in sections' :key='section.id' >
              <!-- L: {{section.series.length}} -->
              <v-row v-if='section.series && section.series.length > 0' >
                <v-col cols=7 >
                    <v-chip
                      v-for='(series, index) in section.series'
                      @click='seriesIndex = index'
                      :key='`chip-${series.name}`' small outlined color='primary' class='mt-2 mr-2' >
                      <v-icon v-if='seriesIndex === index' left small >mdi-check</v-icon>
                      {{series.name}}
                    </v-chip>
                  <v-list class='mt-3' >
                    <template v-for='li in section.series[seriesIndex].objects' >
                      <v-list-item :key='li.id' >
                        <v-list-item-avatar >
                          <v-img v-if='li.icon' :src='li.icon' />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text='li.title' />
                          <v-list-item-subtitle v-text='li.subtitle' />
                        </v-list-item-content>
                        <v-list-item-action >
                          <span v-if='section.series[seriesIndex].currency' >
                            {{ formatCurrency(section.series[seriesIndex].currency, li.value) }}
                          </span>
                          <span v-else v-text='li.value' />
                          <v-list-item-action-text
                            class='caption grey--text'
                            v-text=section.series[seriesIndex].name >
                          </v-list-item-action-text>
                          <v-list-item-action-text class='caption grey--text' >
                            <v-chip x-small color='info' >
                              {{li.percent}}%
                            </v-chip>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-progress-linear
                        class='ml-4' color='amber'
                        :value='li.percent'
                        :key='`prog-${li.id}`' />
                    </template>
                  </v-list>
                </v-col>
                <v-col cols=5 >
                  <!-- {{section.series[seriesIndex].data.series}} -->
                  <vue-apex-charts
                    v-if='hasSeries(section.series[seriesIndex])'
                    type="donut"
                    :options="options"
                    :height='250'
                    :series="section.series[seriesIndex].data.series" />
                </v-col>
              </v-row>
              <v-row v-else >
                <v-card flat>
                  <v-card-title class='subtitle-1 overline' >
                    No data availabe
                  </v-card-title>
                  <v-card-text>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import {mapGetters} from 'vuex'

export default {
  name: 'BreakdownCard',
  components: {VueApexCharts},
  props: {
    title: { type: String, required: false },
    value: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('identity', ['lookups']),
    practitioners () {
      return this.lookups.practitioners
    },
    sections () {
      return this.getObjectPath(this.value, 'sections')
    }
  },
  watch: {
    activeTab () {
      this.seriesIndex = 0
    }
  },
  methods: {
    hasSeries (section) {
      let series = this.getObjectPath(section, 'data.series', false)
      if(series && series.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  data () {
    return {
      activeTab: 0,
      seriesIndex: 0,
      options: {
        chart: {
          type: 'donut',
          sparkline: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  }
}
</script>