<template>
<v-container >
  <v-breadcrumbs :items='crumbs' class='ma-0 pa-0' />
  <h1 v-if='space' class='overline mb-4' >
    <v-btn small icon absolute right @click='get' :loading='req.loading' >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    {{space.title}}
    <v-spacer />
  </h1>

  <request-status :request='req' :message200='false' />
  <v-row >
    <!-- {{metrics}} {{netRevenue}} -->
    <v-col cols=4 v-if='metrics && metrics.count' >
      <metric title='Appointments' :value='metrics.count' />
    </v-col>
    <v-col cols=4 v-if='netRevenue' >
      <metric
        title='Net earned'
        :value='netRevenue'
        :currency='defaultCurrency' >
        <template slot='info' >
          <help-popover >
            <table class='table' >
            <tr>
              <td width='100%' ><strong>Total value of appointments:</strong></td>
              <td class='text-right'>{{ formatCurrency(defaultCurrency, metrics.value) }}</td>
            </tr>
            <tr>
              <td><strong>Discounts:</strong></td>
              <td class='text-right' >{{ formatCurrency(defaultCurrency, metrics.discounts) }}</td>
            </tr>
            <tr>
              <td><strong>Product sales:</strong></td>
              <td class='text-right'>{{ formatCurrency(defaultCurrency, metrics.product_sales) }}</td>
            </tr>
            <tr><td colspan="2" ><v-divider/></td></tr>
            <tr>
              <td><strong>Net earnings</strong></td>
              <td class='text-right'>{{ formatCurrency(defaultCurrency, netRevenue) }}</td>
            </tr>
            </table>
          </help-popover>
        </template>
      </metric>
    </v-col>
    <v-col cols=4 v-if='metrics.collected' >
      <metric
        title='Collected'
        :value='metrics.collected'
        :currency='defaultCurrency' >
      </metric>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols=12 >
      <!-- {{spaceId}}
      <pre v-text='space' /> -->
      <!-- <pre v-text='breakdownmap' /> -->
      <!-- <pre v-text='daterange' /> -->
    <breakdown-card v-model='breakdownmap' />
    <!-- <pre v-text='breakdownmap' /> -->
    </v-col>
  </v-row>

  <h1 class='overline my-4' >Team</h1>
  <v-row
    v-for='(member, index) in members'
    :key='member.practitioner + index' >
    <v-col cols='12' >
      <!-- <pre v-text='member' /> -->
      <practitioner-stats-card
        :from-date='dateRangeParams.from_date'
        :to-date='dateRangeParams.to_date'
        :space-id='spaceId'
        :practitioner-id='member.practitioner'
        :touch='touch'
        v-model='members[index]' />
    </v-col>
  </v-row>

  <!-- <h1 class='overline my-4' >Locations</h1>
  <v-row v-for='(member, index) in members' :key='member.id' >
    <v-col cols='6' >
      <location-stats-card v-model='members[index]' />
    </v-col>
  </v-row> -->


</v-container>
</template>
<script>
import {mapGetters} from 'vuex'
import Metric from '@/components/data/Metric.vue'
import PractitionerStatsCard from '@/components/cards/PractitionerStatsCard.vue'
// import LocationStatsCard from '@/components/cards/LocationStatsCard.vue'
import BreakdownCard from '@/components/cards/BreakdownCard.vue'
import HelpPopover from '@/components/ui/HelpPopover.vue'

export default {
  name: 'SpaceDashboard',
  components: {
    Metric,
    PractitionerStatsCard,
    // LocationStatsCard,
    BreakdownCard,
    HelpPopover
  },
  props: {
    spaceId: { required: true }
  },
  data () {
    return {
      touch: 0,
      req: {
        resource: 'ag.spaces.dashbaord'
      }
    }
  },
  methods: {
    get () {
      if (this.spaceId) {
        this.req.resourceId = this.spaceId
        this.req.params = this.dateRangeParams
        this.$request('get', this.req)
      }
    },
    getMetric (key) {
      if (this.metrics && (key in this.metrics)) { return parseFloat(this.metrics[key]) }
      return 0
    },
    formatBreakdown (key) {
      let bd = this.getObjectPath(this.breakdowns, key)
      if (bd) {
        let labels = Object.keys(bd)
        let series = Object.values(bd).map((val) => { return parseFloat(val) })
        return {labels, series}
      }
      return {labels: [], series: []}
    },
    formatPractitionerBreakdown (bdIndex) {
      let data = this.formatBreakdown(bdIndex)
      let total = data.series.reduce((prev, current) => {
        return prev + current
      }, 0)
      let dataLookups = JSON.parse(JSON.stringify(data.labels))

      let objects = this.members.map((member) => {
        let value = 0
        let index = dataLookups.indexOf(String(member.practitioner))
        if (index > -1) {
          value = data.series[index]
        }
        data.labels = data.labels.map((lbl) => {
          let p = this.getPractitionerById(lbl)
          if (p) { return p.full_name }
          return lbl
        })
        let percent = 0
        if (total > 0 && value > 0) {
          percent = Math.round(value / total * 100)
        }
        let icon = this.getObjectPath(member, 'practitioner_media.profile')
        return {
          id: member.practitioner,
          title: member.practitioner_name,
          subtitle: member.practitioner_profession,
          icon,
          value: value,
          percent
        }
      })
      return {data, objects}
    },
    genericBreakdown (bdIndex) {
      let data = this.formatBreakdown(bdIndex)
      let total = data.series.reduce((prev, current) => {
        return prev + current
      }, 0)
      let objects = data.labels.map((lbl, index) => {
        let percent = 0
        let value = data.series[index]
        if (total > 0 && value > 0) {
          percent = Math.round(value / total * 100)
        }
        return {
          id: index,
          title: lbl,
          value,
          percent
        }
      })
      return {data, objects}
    }
  },
  watch: {
    spaceId: {
      immediate: true,
      handler () {
        this.get()
      }
    },
    daterange () {
      if (this.daterange && this.daterange.length === 2) {
        this.get()
      }
    }
  },
  computed: {
    ...mapGetters(['daterange', 'daterangeText', 'dateRangeParams']),
    ...mapGetters('identity', ['getSpaceById', 'getPractitionerById']),
    space () {
      return this.getSpaceById(this.spaceId)
    },
    crumbs () {
      let spaceName = ''
      if (this.space && this.space.title) {
        spaceName = this.space.title
      }
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/'
        },
        {
          text: spaceName,
          disabled: true
        }
      ]
    },
    netRevenue () {
      if (this.metrics) {
        let net = this.getMetric('value')
        let discounts = this.getMetric('discounts')
        let products = this.getMetric('product_sales')
        if (discounts > 0 ) { net = net - discounts }
        if (products > 0) { net = net + products }
        return net
      }
      return null
    },
    metrics () {
      return this.getObjectPath(this.req, 'result.metrics', {})
    },
    breakdowns () {
      return this.getObjectPath(this.req, 'result.breakdowns', {})
    },
    breakdownmap () {
      let earned = this.formatPractitionerBreakdown('practitioner_sum')
      let received = this.formatPractitionerBreakdown('practitioner_collected_sum')
      let count = this.formatPractitionerBreakdown('practitioner_count')

      let earnedStatus = this.genericBreakdown('status_sum')
      let receivedStatus = this.genericBreakdown('status_collected_sum')
      let countStatus = this.genericBreakdown('status_count')

      let earnedLocation = this.genericBreakdown('location_sum')
      let receivedLocation = this.genericBreakdown('location_collected_sum')
      let countLocation = this.genericBreakdown('location_count')

      let earnedProduct = this.genericBreakdown('appointment_type_sum')
      let receivedProduct = this.genericBreakdown('appointment_type_collected_sum')
      let countProduct = this.genericBreakdown('appointment_type_count')

      return {
        sections: [
          {
            id: 'practitioner',
            text: 'Practitioner',
            series: [
              {
                name: 'Earned',
                data: earned.data,
                objects: earned.objects,
                currency: 'ZAR'
              },
              {
                name: 'Received',
                data: received.data,
                objects: received.objects,
                currency: 'ZAR'
              },
              {
                name: 'Count',
                data: count.data,
                objects: count.objects,
                currency: null
              }
            ]
          },
          {
            id: 'location',
            text: 'Location',
            series: [
              {
                name: 'Earned',
                data: earnedLocation.data,
                objects: earnedLocation.objects,
                currency: 'ZAR'
              },
              {
                name: 'Received',
                data: receivedLocation.data,
                objects: receivedLocation.objects,
                currency: 'ZAR'
              },
              {
                name: 'Count',
                data: countLocation.data,
                objects: countLocation.objects,
                currency: null
              }
            ]
          },
          {
            id: 'status',
            text: 'Status',
            series: [
              {
                name: 'Earned',
                data: earnedStatus.data,
                objects: earnedStatus.objects,
                currency: 'ZAR'
              },
              {
                name: 'Received',
                data: receivedStatus.data,
                objects: receivedStatus.objects,
                currency: 'ZAR'
              },
              {
                name: 'Count',
                data: countStatus.data,
                objects: countStatus.objects,
                currency: null
              }
            ]
          },
          {
            id: 'appointment-type',
            text: 'Appointment type',
            series: [
              {
                name: 'Earned',
                data: earnedProduct.data,
                objects: earnedProduct.objects,
                currency: 'ZAR'
              },
              {
                name: 'Received',
                data: receivedProduct.data,
                objects: receivedProduct.objects,
                currency: 'ZAR'
              },
              {
                name: 'Count',
                data: countProduct.data,
                objects: countProduct.objects,
                currency: null
              }
            ]
          }
        ]
      }
    },
    members () {
      return this.getObjectPath(this.req, 'result.members', [])
      // let members = this.getObjectPath(this.space, 'members', [])
      // return members.map((id) => {
      //   return this.getPractitionerById(id)
      // })
    },
    kpicols () {
      return Math.ceil(12 / Object.keys(this.metrics).length)
    }
  }
}
</script>
