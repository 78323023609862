<template>
<div>
  <v-data-table dense striped hide-default-footer
    :headers="headers"
    :items="items" />
  <!-- <pre v-text="headers" />
  <pre v-text="items" /> -->
</div>
</template>
<script>
export default {
  name: 'AppointmentTable',
  props: {
    items: { type: Array, default: () => { return [] } }
  },
  methods: {
    getFieldName (field) {
      if (field) {
        return field.split('_').join(' ')
      }
      return ''
    }
  },
  computed: {
    headers () {
      if (this.items && this.items.length > 0) {
        let fields = Object.keys(this.items[0])
        return fields.map((field) => {
          return {
            text: this.getFieldName(field),
            value: field
          }
        })
      }
      return []
    }
  }
}
</script>
