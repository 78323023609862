<template>
<v-card class='elevation-20' height='100%'>
  <v-card-text>
    <h2
      v-if='currency'
      class='mb-0 pb-0 pt-4 font-weight-regular'>{{ formatCurrency(currency, value) }}
      <slot name='info'></slot>
    </h2>
    <h2
      v-else
      class='mb-0 pb-0 pt-4 font-weight-regular'>{{ value }}
    </h2>
    <v-subheader class='pa-0 overline font-weight-light' v-text='title' />
  </v-card-text>
</v-card>
</template>
<script>
export default {
  name: 'Metric',
  props: {
    value: { required: true},
    title: { required: true},
    currency: { required: false}
  }
}
</script>
