<template>
<!-- color='blue-grey darken-3' -->
<v-card class='elevation-10' >
  <!-- <pre v-text='request' /> -->
  <request-status :request='request' :message200='false' />
  <v-list >
    <v-list-item >
      <v-list-item-avatar @click='get' >
        <v-img
          v-if='value.practitioner_media && value.practitioner_media.profile'
          :src='value.practitioner_media.profile' />
        <v-avatar color='teal' class='white--text text-center' v-else >
          <span>{{ getInitials(value.practitioner_name, 2) }}</span>
        </v-avatar>
        <!-- <v-icon v-else >mdi-person</v-icon> -->
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title >
          {{ value.practitioner_name }}
          <v-chip small label close
            @click:close='showView("")'
            v-if='extraFilterName'
            color='info'
            class='ml-3' >
              <v-icon small left >mdi-filter</v-icon>
              {{extraFilterName}}</v-chip>
        </v-list-item-title>
        <v-list-item-subtitle >
          {{ value.practitioner_profession }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if='value.commission_percent' >
        {{ value.commission_percent }}%
        <v-list-item-action-text class='caption grey--text' >
          Commission
        </v-list-item-action-text>
      </v-list-item-action>
      <v-list-item-action >
        <v-btn @click='details=!details' text >Details
          <v-icon small >mdi-chevron-down</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
  <v-card-text>
    <v-row >
      <v-col cols='6' >
        <v-row >
          <v-col
            v-for='kpi in kpis'
            :key='kpi.title'
            :cols='4' >
            <v-card outlined
              @click='showView(kpi.title)'
              height="100%" >
              <v-card-text>
                <v-overlay absolute
                  v-show='request.loading' >
                  <v-progress-circular indeterminate width='2' color='amber' />
                </v-overlay>
                <div v-if='kpi.currency'
                  class='subtitle-1 font-weight-regular'
                  v-text='formatCurrency( kpi.currency, kpi.value)' />
                <div v-else class='subtitle-1 font-weight-regular' v-text='kpi.value' ></div>
                <div class='text-uppercase caption font-weight-light' >
                  {{kpi.title}}
                  <help-popover small v-if='kpi.title === "value"'>
                    <table class='table' >
                    <tr>
                      <td width='100%' ><strong>Total value of appointments:</strong></td>
                      <td class='text-right'>{{ formatCurrency(defaultCurrency, totalValue) }}</td>
                    </tr>
                    <tr>
                      <td><strong>Discounts:</strong></td>
                      <td class='text-right' >{{ formatCurrency(defaultCurrency, discounts) }}</td>
                    </tr>
                    <tr>
                      <td><strong>Product sales:</strong></td>
                      <td class='text-right'>{{ formatCurrency(defaultCurrency, productSales) }}</td>
                    </tr>
                    <tr><td colspan="2" ><v-divider/></td></tr>
                    <tr>
                      <td><strong>Net earnings</strong></td>
                      <td class='text-right'>{{ formatCurrency(defaultCurrency, netRevenue) }}</td>
                    </tr>
                    </table>
                  </help-popover>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" >
        <vue-apex-charts
          v-if='series && series.length > 0'
          type="area"
          :options="options"
          :series="series"
          :height=180 />
        <!-- <pre v-text='series' /> -->
      </v-col>
    </v-row>
    <!-- <v-row>
      A stats chip for each location ..
    </v-row> -->
    <!-- <pre v-text='value' /> -->
    <template v-if='details' >
      <v-card class="elevation-5" >
        <v-toolbar dense flat class='mt-5 grey lighten-4' >
          <span class='overline' >Appointments</span>
          <v-spacer />
          <v-btn
            @click='page--'
            :disabled='page===1' icon >
              <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Page {{page}} of {{pagination.numPages}}
          <v-btn icon
            @click='page++'
            :disabled='page===pagination.numPages' >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <v-divider vertical />
          <v-btn icon @click='openDownloader' >
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
        </v-toolbar>
        <appointment-table :items='appointments' />
      </v-card>
    </template>
  </v-card-text>
  <v-dialog v-model='showDownload' width=400 >
    <v-card>
      <v-card-title class='overline' >
        Export to CSV
      </v-card-title>
      <v-card-text class='text-center'>
        This will export the visible table data to a csv file
        <div v-if='downloadProgress > 0' >
          <v-progress-circular class='mt-3'
            :value='downloadProgress'
            size='60' color="amber" :rotate="-90" >
            {{ downloadProgress }}%
          </v-progress-circular>
          <div>{{downloadMessage}}</div>
        </div>
      </v-card-text>
      <v-card-actions >
        <v-btn @click='showDownload=false' text >Cancel</v-btn>
        <v-spacer/>
        <v-btn
          :loading='downloading'
          @click='toCSV' color='primary' >Start downloading</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import AppointmentTable from '@/components/tables/AppointmentTable.vue'
import HelpPopover from '@/components/ui/HelpPopover.vue'
import Papa from 'papaparse/papaparse'
// import Vue from 'vue'

export default {
  name: 'PractitionerStatsCard',
  components: {VueApexCharts, AppointmentTable, HelpPopover},
  props: {
    spaceId: { required: true },
    practitionerId: { required: true },
    value: { type: Object, default: () => { return {} } },
    flat: { type: Boolean, default: true },
    attrs: { type: Object, default: () => {} },
    touch: { type: Number, default: 0 },
    fromDate: { type: String },
    toDate: { type: String }
  },
  data () {
    return {
      details: false,
      showDownload: false,
      downloadProgress: 0,
      downloadMessage: null,
      downloading: false,
      extraFilters: {},
      extraFilterName: null,
      page: 1,
      request: {
        resource: 'ag.spaces',
        extra: 'dashboard'
      }
    }
  },
  mounted () {
    this.get()
  },
  computed: {
    watchString () {
      return `${this.fromDate}${this.toDate}${this.touch}${this.page}`
    },
    count () {
      return this.getObjectPath(this.request, 'result.count', 0)
    },
    pages () {
      return this.getObjectPath(this.request, 'result.pages', 0)
    },
    totalValue () {
      return this.getObjectPath(this.request, 'result.data.aggregates.value_sum', 0)
    },
    discounts () {
      return this.getObjectPath(this.request, 'result.data.aggregates.discounts', 0)
    },
    productSales () {
      return this.getObjectPath(this.request, 'result.data.aggregates.product_sales', 0)
    },
    netRevenue () {
      let net = this.getMetric(this.totalValue)
      let discounts = this.getMetric(this.discounts)
      let products = this.getMetric(this.productSales)
      if (discounts > 0 ) { net = net - discounts }
      if (products > 0) { net = net + products }
      return net
    },
    totalPaid () {
      return this.getObjectPath(this.request, 'result.data.aggregates.collected', 0)
    },
    cancellations () {
      return this.getObjectPath(this.request, 'result.data.annotates.status_count.C', 0)
    },
    clients () {
      return this.getObjectPath(this.request, 'result.data.counts.num_clients', 0)
    },
    appointments () {
      return this.getObjectPath(this.request, 'result.results', [])
    },
    pagination () {
      return {
        page: this.page,
        numPages: this.pages
      }
    },
    options () {
      // let data = this.getObjectPath(this.request, 'result.data.timeseries.appointment_count', {})
      // let xaxis = Object.values(data).map((point) => { return point.x })
      return {
        chart: {
          type: 'area',
          sparkline: { enabled: true, height: 100 }
        },
        tooltip: { theme: 'dark' },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', width: 2 }
        // xaxis
      }
    },
    series () {
      let data = this.getObjectPath(this.request, 'result.data.timeseries.appointment_count', {})
      // data = Object.values(data).map((point) => { return point.y })
      return [
        {name: 'Appointments', data}
      ]
    },
    kpis () {
      return [
        {
          title: 'value',
          currency: 'ZAR',
          value: this.netRevenue
        },
        {
          title: 'collected',
          currency: 'ZAR',
          value: this.totalPaid
        },
        {
          title: 'outstanding',
          currency: 'ZAR',
          value: (this.netRevenue - this.totalPaid)
        },
        {
          title: 'appts',
          value: this.count,
        },
        {
          title: 'clients',
          value: this.clients,
        },
        {
          title: 'cancellations',
          value: this.cancellations
        }
      ]
    }
  },
  watch: {
    watchString () {
      this.get()
    }
  },
  methods: {
    getMetric (val) {
      if (val) { return parseFloat(val) }
      return 0
    },
    configureRequest (request, custompage) {
      request.resourceId = this.spaceId
      let page = this.page
      if (custompage) { page = custompage }
      let params = {
        practitioner: this.practitionerId,
        page
      }
      if (this.extraFilters) {
        Object.assign(params, this.extraFilters)
      }
      request.params = params
      if (this.fromDate) { request.params.after_utc = this.fromDate }
      if (this.toDate) { request.params.before_utc = this.toDate }
    },
    showView (viewId) {
      this.extraFilters = {}
      this.extraFilterName = null
      if (viewId === 'outstanding') {
        this.extraFilterName = viewId
        this.extraFilters = {
          'status-in': 'N,C,I'
        }
      }
      if (viewId === 'collected') {
        this.extraFilterName = viewId
        this.extraFilters = {
          'status-in': 'P'
        }
      }
      if (viewId === 'cancellations') {
        this.extraFilterName = viewId
        this.extraFilters = {
          'status-in': 'C'
        }
      }
      this.get()
    },
    get () {
      this.configureRequest(this.request)
      this.$request('get', this.request)
    },
    openDownloader () {
      this.downloadProgress = 0
      this.showDownload = true
      this.downloadMessage = null
    },
    download (csvString) {
      var blob = new Blob([csvString])
      let filename = `appointments.csv`
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
      } else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {type: 'text/csv'})
          a.download = filename;
          document.body.appendChild(a)
          // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
          a.click();
          document.body.removeChild(a);
      }
    },
    async toCSV () {
      let pages = []


      this.downloadMessage = 'Fetching data'
      this.downloading = true
      for (let x=0; x<this.pages; x++) {
        let config = JSON.parse(JSON.stringify(this.request))
        this.configureRequest(config, (x + 1))
        let res = await this.$request('get', config)
        this.downloadProgress = 1
        if (res.status === 200) {
          pages = pages.concat(res.data.results)
          console.log(x)
          if (x > 0) {
            let percent = Math.round(x / parseInt(this.pages) * 100)
            console.log(percent)
            this.downloadProgress = percent
          }
        }
      }
      this.downloadMessage = 'Generating CSV file'
      let csv = Papa.unparse(pages)
      this.download(csv)
      this.downloadMessage = 'Done!'
      this.downloadProgress = 100
      this.downloading = false
      return csv
    }
  }
}
</script>
