<template>
<v-menu
  v-model="showInfo"
  open-on-hover close-on-content-click
  :nudge-width="200"
  offset-x >
  <template v-slot:activator="{ on }" >
    <v-icon :small='small' :color='color' v-on="on" >{{icon}}</v-icon>
  </template>
  <v-card style='width:250px;' >
    <v-card-text>
      <slot>
      </slot>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click='showInfo=false' text small >{{cancelText}}</v-btn>
    </v-card-actions>
  </v-card>
</v-menu>
</template>

<script>
export default {
  name: 'HelpPopover',
  props: {
    initiallyVisible: { type: Boolean, default: false },
    icon: { type: String, default: 'mdi-information' },
    withCancel: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    cancelText: { type: String, default: 'Got it' },
    color: { type: String, default: 'primary' }
  },
  mounted () {
    if (this.initiallyVisible) {
      this.showInfo = true
    }
  },
  data () {
    return {
      showInfo: false
    }
  }
}
</script>

<style>

</style>
